
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// WEB APP's FIREBASE CONFIG 
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: "viadis-eshop.firebaseapp.com",
  projectId: "viadis-eshop",
  storageBucket: "viadis-eshop.appspot.com",
  messagingSenderId: "484404416053",
  appId: "1:484404416053:web:5ea3fd047fc3f1ea09ab7c"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export default app;