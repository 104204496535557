import { addDoc, collection, doc, setDoc, Timestamp } from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { db, storage } from "../../../firebase/config";
import Card from "../../card/Card";
import styles from "./AddProduct.module.scss";
import { selectProducts } from "../../../redux/slice/productSlice";

const categories = [
  { id: 1, name: "Appareillage" },
  { id: 2, name: "Domotique" },
  { id: 3, name: "Boîtes & Coffrets Électrique" },
  { id: 4, name: "Canalisation & Signalisation" },
  { id: 5, name: "Protection & Securité" },
  { id: 6, name: "Accessoires d’Installations Basse Tension" },
  { id: 7, name: "Éclairage Résidentiel" },
  { id: 8, name: "Éclairage Tertiaire & Industriel" },
  { id: 9, name: "Sources d'Énégie" },
  { id: 10, name: "Distribution Réseaux Eléctriques" },
  { id: 11, name: "Industrie" },
  { id: 12, name: "Précâblage Informatique IPS" },
  { id: 13, name: "Câbles Électriques" },
];

const initialState = {
  name: "",
  ref: "",
  imageURL: "",
  price: 0,
  category: "",
  brand: "",
  desc: "",
  stock: 0,
};

const AddProduct = () => {
  const { id } = useParams();
  const products = useSelector(selectProducts);
  const productEdit = products.find((item) => item.id === id);
  console.log(productEdit);

  const [product, setProduct] = useState(() => {
    const newState = detectForm(id, { ...initialState }, productEdit);
    return newState;
  });

  const [uploadProgress, setUploadProgress] = useState(0);
  // const [setIsLoading] = useState(false);
  const navigate = useNavigate();

  function detectForm(id, f1, f2) {
    if (id === "ADD") {
      return f1;
    }
    return f2;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // console.log(file);

    const storageRef = ref(storage, `eshop/${Date.now()}${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        toast.error(error.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setProduct({ ...product, imageURL: downloadURL });
          toast.success("Image uploaded successfully.");
        });
      }
    );
  };

  const addProduct = (e) => {
    e.preventDefault();
    // console.log(product);
    // setIsLoading(true);

    try {
      /* eslint-disable no-unused-vars */
      const docRef = addDoc(collection(db, "products"), {
        name: product.name,
        reference: product.reference,
        imageURL: product.imageURL,
        price: Number(product.price),
        category: product.category,
        brand: product.brand,
        desc: product.desc,
        stock: product.stock,
        createdAt: Timestamp.now().toDate(),
      });
      /* eslint-disable no-unused-vars */
      // setIsLoading(false);
      setUploadProgress(0);
      setProduct({ ...initialState });

      toast.success("Le produit a été ajouté à la base de données!");
      navigate("/admin/add-product/ADD");
    } catch (error) {
      // setIsLoading(false);
      toast.error(error.message);
    }
  };

  const editProduct = (e) => {
    e.preventDefault();
    // setIsLoading(true);

    if (product.imageURL !== productEdit.imageURL) {
      const storageRef = ref(storage, productEdit.imageURL);
      deleteObject(storageRef);
    }

    try {
      setDoc(doc(db, "products", id), {
        name: product.name,
        reference: product.reference,
        imageURL: product.imageURL,
        price: Number(product.price),
        category: product.category,
        brand: product.brand,
        desc: product.desc,
        stock: product.stock,
        createdAt: productEdit.createdAt,
        editedAt: Timestamp.now().toDate(),
      });
      // setIsLoading(false);
      toast.success("Le produit a été modifié avec succès!");
      navigate("/admin/add-product/ADD");
    } catch (error) {
      // setIsLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className={styles.product}>
        <h2 className={styles.titre}>{detectForm(id, "Ajouter un produit", "Modifier un produit")}</h2>
        <Card cardClass={styles.card}>
          <form onSubmit={detectForm(id, addProduct, editProduct)}>
          <label>Référence du produit :</label>
            <input
              type="text"
              // placeholder="Product reference"
              required
              name="reference"
              value={product.reference}
              onChange={(e) => handleInputChange(e)}
            />
            <label>Nom du produit :</label>
              <input
                type="text"
                // placeholder="Product name"
                required
                name="name"
                value={product.name}
                onChange={(e) => handleInputChange(e)}
            />

            <label>Image du produit :</label>
            <Card cardClass={styles.group}>
              {uploadProgress === 0 ? null : (
                <div className={styles.progress}>
                  <div
                    className={styles["progress-bar"]}
                    style={{ width: `${uploadProgress}%` }}
                  >
                    {uploadProgress < 100
                      ? `Uploading ${uploadProgress}`
                      : `Upload Complete ${uploadProgress}%`}
                  </div>
                </div>
              )}

              <input
                type="file"
                accept="image/*"
                placeholder="Product Image"
                name="image"
                onChange={(e) => handleImageChange(e)}
              />

              {product.imageURL === "" ? null : (
                <input
                  type="text"
                  required
                  placeholder="Image URL"
                  name="imageURL"
                  value={product.imageURL}
                  disabled
                />
              )}
            </Card>

            <label>Prix en dirhams du produit:</label>
            <input
              type="number"
              // placeholder="Product price"
              required
              name="price"
              value={product.price}
              onChange={(e) => handleInputChange(e)}
            />
            <label>Séléctionner la famille du produit / catégorie :</label>
            <select
              required
              name="category"
              value={product.category}
              onChange={(e) => handleInputChange(e)}
            >
              <option value="" disabled>
                -- Choisir la catégorie --
              </option>
              {categories.map((cat) => {
                return (
                  <option key={cat.id} value={cat.name}>
                    {cat.name}
                  </option>
                );
              })}
            </select>

            <label>Marque du produit :</label>
            <input
              type="text"
              // placeholder="Product brand"
              required
              name="brand"
              value={product.brand}
              onChange={(e) => handleInputChange(e)}
            />

            <label>Description du produit :</label>
            <textarea
              name="desc"
              required
              value={product.desc}
              onChange={(e) => handleInputChange(e)}
              cols="30"
              rows="4"
            ></textarea>

            <label>Stock du produit:</label>
            <input
              type="number"
              placeholder="Stock du produit"
              required
              name="stock"
              value={product.stock}
              onChange={(e) => handleInputChange(e)}
            />
            <button className="--btn --btn-primary btn-add">
              {detectForm(id, "Sauvegarder le produit", "Appliquer les modifications")}
            </button>
          </form>
        </Card>
      </div>
    </>
  );
};

export default AddProduct;
