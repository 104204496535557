export const sliderData = [
    {
      image: "https://images.unsplash.com/photo-1510595256055-e44b27efe497?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1150&q=80",
      heading: "VIADIS.MA",
      desc: "Expert en matériel électrique, nous vous offrons une large gamme de produits de qualité.",
    },
    {
      image: "https://images.unsplash.com/photo-1508790762848-8a3096277c8f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1188&q=80",
      heading: "Commandez dès maintenant",
      desc: "Trouvez tout le matériel électrique dont vous avez besoin en un seul endroit.",
    },
    {
      image: "https://images.unsplash.com/photo-1504972090022-6edb81e4e534?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      heading: "Consultez notre catalogue",
      desc: "Des milliers de produits électriques à des prix compétitifs.",
    },
    {
      image: "https://images.unsplash.com/photo-1544724569-5f546fd6f2b5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      heading: "Faites une demande de devis",
      desc: "Notre équipe est là pour vous aider à trouver le matériel électrique adapté à vos besoins.",
    },
    {
      image: "https://images.unsplash.com/photo-1625276254563-f0fbbf66a5e7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      heading: "Soldes Outillage",
      desc: "Tournevis ou pince coupante, une large gamme d'outillage à disposition en remise jusqu'à -15%.",
    },
    
  ];
  